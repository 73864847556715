/* ==========================================================================
LazyLoad
========================================================================== */
const LazyLoad = require('vanilla-lazyload');

const lazyLoadInstance = new LazyLoad({
  elements_selector: '.lazy'
});

/* eslint-disable */

$(() => {
  if($('.sbs_plp_submitViaAjax').length) {
    $('.sbs_plp_submitViaAjax').each(function() {
      const $PLP = $(this);
      $PLP.find('form').on('submit', function(event) {
        const $theForm = $(this);
        console.log($theForm);
        $PLP.find('form, .ccm-block-page-list-pages').fadeTo(1, .5);
        $PLP.find('.plp_form_loading').show();
        const formValues = $theForm.serialize();
        Concrete.event.publish('page_list_plus/submit', {
          vals: formValues,
          form: $theForm
        });
        return false;
      });
    });

    $('.sbs_plp_receiveViaAjax').on('click', '.pagination a', function() {
      Concrete.event.publish('page_list_plus/paginate', this);
      return false;
    });

    Concrete.event.subscribe("page_list_plus/paginate", function(e, el) {
      const $el = $(el);
      const $PLP = $el.closest('.sbs_plp_receiveViaAjax');
      $PLP.find('form, .ccm-block-page-list-pages').fadeTo(1, .5);
      $PLP.find('.plp_form_loading').show();
      scrollToTop($PLP);
      sbs_plp_ajaxGet($el.attr('href'), $PLP);
    });

    Concrete.event.subscribe("page_list_plus/submit", function(e, data) {
      $('.sbs_plp_receiveViaAjax').each(function() {
        const $PLP = $(this);
        $PLP.attr('data-serialized',data.vals);
        const bID = $PLP.attr('data-bID');
        const cID = $PLP.attr('data-cID');
        const url = $PLP.find('.reloadURL').val()+"?bID="+bID+"&cID="+cID+"&ccm_paging_p=1&"+data.vals;
        scrollToTop($PLP);
        sbs_plp_ajaxGet(url, $PLP);
      });
    });
  }
});

function sbs_plp_ajaxGet(url, $PLP) {
  $.ajax({
    type: 'GET',
    url: url,
    error: function(resp, status, errorthrown) {
      const $submitViaAjax = $('.sbs_plp_submitViaAjax');
      $submitViaAjax.find('form, .ccm-block-page-list-pages').fadeTo(.5, 1);
      $submitViaAjax.find('.plp_form_loading').hide();
    },
    success: function(resp) {
      const $resp = $(resp);
      const templateResultAreas = ['.ccm-block-page-list-pages[data-bID="' + $PLP.attr('data-bID') + '"]','.pagination'];
      for (let i = 0; i < templateResultAreas.length; i++) {
        var $responseResults = $resp.find(templateResultAreas[i]);
        $PLP.find(templateResultAreas[i]).html($responseResults.children());
      }
      Concrete.event.publish('infinite_scroller/reset', $PLP);
      const $submitViaAjax = $('.sbs_plp_submitViaAjax');
      $submitViaAjax.find('form, .ccm-block-page-list-pages').fadeTo(.5, 1);
      $submitViaAjax.find('.plp_form_loading').hide();

      if (lazyLoadInstance) {
        lazyLoadInstance.update();
      }
    }
  });
}

function scrollToTop ($PLP) {
  const navbarHeight = $('.navbar').outerHeight();
  $('html, body').animate({
    scrollTop: $PLP.offset().top - navbarHeight
  }, 1000);
}

/* eslint-enable */
