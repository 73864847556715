/* ==========================================================================
Plyr
https://github.com/sampotts/plyr
========================================================================== */
import Plyr from 'plyr';

/* ==========================================================================
Hero Plyr
========================================================================== */
const player = new Plyr('#heroVideo', {
  fullscreen: {
    iosNative: true
  },
  muted: false,
  autoplay: false,
  youtube: {
    noCookie: true,
    rel: 0,
    showinfo: 0,
    iv_load_policy: 3,
    modestbranding: 1
  },
  hideControls: false,
  captions: {
    active: true
  }
});

if ($('#heroVideo').length) {
  player.toggleControls(false);

  player.on('playing', event => {
    $('.hero--video-background').css('opacity', 1);
    player.toggleControls(true);
  });
}
