/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
import Swiper from 'swiper';

/* ==========================================================================
Card swiper
========================================================================== */
function videoSwiper () {
  const swiper = new Swiper('#videoSwiper', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.video-swiper-next',
      prevEl: '.video-swiper-prev'
    },
    loop: true,
    effect: 'swipe'
  });
  return swiper;
}

if ($('#videoSwiper').length) {
  videoSwiper();
}

// console.log('video-block-swiper.js is loading');
