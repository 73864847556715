/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
import Swiper from 'swiper';

/* ==========================================================================
Filters
========================================================================== */
function pagelistFilters () {
  var swiper = new Swiper('#pagelistFilters', {
    loop: false,
    effect: 'swipe',
    freeMode: true,
    mousewheel: {
      releaseOnEdges: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1.5
      },
      768: {
        slidesPerView: 2.5
      },
      991: {
        slidesPerView: 3.5
      },
      1430: {
        slidesPerView: 4.5
      }
    }
  });
  return swiper;
}

if ($('#pagelistFilters').length) {
  pagelistFilters();
}
