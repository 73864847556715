/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
import Swiper from 'swiper';

/* ==========================================================================
Hero
========================================================================== */
function heroImages () {
  var swiper = new Swiper('#heroImages', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    effect: 'fade'
  });
  return swiper;
}

if ($('#heroImages').length) {
  heroImages();
}
