/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
import Swiper from 'swiper';

/* ==========================================================================
Card swiper
========================================================================== */
function cardSwiper () {
  const swiper = new Swiper('#cardSwiper', {
    pagination: {
      el: '.card-swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.card-swiper-next',
      prevEl: '.card-swiper-prev'
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    breakpoints: {
      320: {
        slidesPerView: 1.2
      },
      767: {
        slidesPerView: 2.2
      },
      1200: {
        slidesPerView: 3.2
      }
    },
    loop: false,
    effect: 'swipe'
  });
  return swiper;
}

if ($('#cardSwiper').length) {
  cardSwiper();
}

// console.log('card-default-swiper.js is loading');
