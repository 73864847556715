const $form = $('.check__form form');
const $inputs = $form.find('input');
const $submitBtn = $form.find('button[type="submit"]');

const $checkForm = $('.check__form');
const $planBlock = $('.plans__block');
const $planSalaryAdministration = $('#plan-salary-administration');
const $planHrCustom = $('#plan-hr-custom');
const $planHrPremium = $('#plan-hr-premium');

const answers = [];

function setActivePlan (plan) {
  const $self = plan;
  const siblings = $self.siblings();

  $self.removeClass('col__plans').addClass('col__best-choice');
  $self.find('.plans__item').addClass('plans__best-choice');
  $self.find('.best__choice').removeClass('d-none');

  siblings.removeClass('col__best-choice').addClass('col__plans');
  siblings.find('.plans__item').removeClass('plans__best-choice');
  siblings.find('.best__choice').addClass('d-none');
}

function scrollToTop () {
  $('html, body').animate({
    scrollTop: 0
  }, 1000);
}

$inputs.on('change', (e) => {
  const $self = $(e.target);
  const value = $self.val();
  const name = $self.attr('name');

  answers[name] = value;

  if (Object.keys(answers).length === 3) {
    $submitBtn.prop('disabled', false);
  } else {
    $submitBtn.prop('disabled', true);
  }
});

$form.submit((e) => {
  e.preventDefault();

  if (answers.employees === '1-9' && answers['hr-processes'] === '0' && answers['hr-reports'] === '0') {
    setActivePlan($planSalaryAdministration);
  } else if (answers.employees === '1-9' && answers['hr-processes'] === '1' && answers['hr-reports'] === '0') {
    setActivePlan($planHrPremium);
  } else if (answers.employees === '10-19' && answers['hr-processes'] === '1' && answers['hr-reports'] === '0') {
    setActivePlan($planHrPremium);
  } else if (answers.employees === '20+' && answers['hr-processes'] === '1' && answers['hr-reports'] === '1') {
    setActivePlan($planHrCustom);
  } else {
    setActivePlan($planHrCustom);
  }

  scrollToTop();

  $checkForm.fadeOut('fast', () => {
    $planBlock.fadeIn('fast');
  });
});
