/* ==========================================================================
Bootstrap4 - Navbar
========================================================================== */
const body = $('body');
const navbar = $('#navbar');
const navbarBrand = $('#navbar .navbar-brand img');
const logo = navbarBrand.attr('src');
const navbarHeight = navbar.outerHeight();
let scroll = $(window).scrollTop();

$(window).scroll(function () {
  scroll = $(window).scrollTop();
  navbarScroll(scroll);
});

navbarScroll(scroll);

function navbarScroll (scroll) {
  if (scroll >= navbarHeight) {
    body.addClass('navbar--scroll');
    const newLogo = logo.replace('primary', 'secondary');
    navbarBrand.attr('src', newLogo);
  } else {
    body.removeClass('navbar--scroll');
    navbarBrand.attr('src', logo);
  }
}

if ($('body').hasClass('navbar--open')) {
  console.log('test');
  const newLogo = logo.replace('secondary', 'primary');
  navbarBrand.attr('src', newLogo);
} else {
  navbarBrand.attr('src', logo);
}
