/* ==========================================================================
Swiper
https://swiperjs.com/
========================================================================== */
import Swiper from 'swiper';

/* ==========================================================================
Card Default
========================================================================== */
function cardDefault () {
  var swiper = new Swiper('#cardDefault', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
    },
    loop: true,
    effect: 'swipe'
  });
  return swiper;
}

if ($('#cardDefault').length) {
  cardDefault();
}
