/* ==========================================================================
Plyr
https://github.com/sampotts/plyr
========================================================================== */
import Plyr from 'plyr';

/* ==========================================================================
YouTube
========================================================================== */
Array.from(document.querySelectorAll('.youtubeBlock')).map((player) => {
  player.addEventListener('click', () => {
    mountIframe(player);
  }, { once: true });
});

function mountIframe (container) {
  const videoId = container.dataset.videoId;
  const size = container.dataset.size;
  const params = container.dataset.params;
  const domain = container.dataset.domain;

  new Promise((resolve, reject) => {
    container.innerHTML =
      '<iframe src="//' + domain + '/embed/' + videoId + '' + params + '"' +
      size +
      'allow="autoplay" allowfullscreen loading="lazy"></iframe>';
    resolve();
  }).then((result) => {
    const video = new Plyr(container, {
      fullscreen: {
        iosNative: true
      },
      youtube: {
        noCookie: true
      }
    });
    video.on('click', event => {
      // mobile on touch togglePlay
      if (video.touch) {
        video.togglePlay();
      }
    });
  });
}
