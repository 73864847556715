/* ==========================================================================
Bootstrap4 - Navbar collapse
========================================================================== */
$('[data-toggle="offcanvas"]').on('click', function () {
  // Toggle class for navigation button "hamburger"
  $('.hamburger').toggleClass('is-active');

  // Toggle body class
  $('body').toggleClass('navbar--open');
});
